export const Footer = () => {
  return (
    <footer className="w-full bg-green-900 text-white">
      <div className="flex flex-col gap-10 items-center justify-center py-9 px-5 md:px-10">
        <div className="grid gap-20 w-8/12 sm:w-10/12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:w-full max-w-screen-lg ">
          <div className="flex flex-col gap-3 items-start">
            <h1 className="text-2xl font-bold">Support Center</h1>
            GETTHEHERPES@GMAIL.COM
          </div>
          <div className="flex flex-col gap-3 items-start">
            <h1 className="text-2xl font-bold">Community</h1>
            <a href="https://twitter.com/pepeherpe" target="_blank" rel="noopener noreferrer" className="hover:text-blue-600">
                TWITTER
            </a>
            <a href="https://t.me/Pepeherpes" target="_blank" rel="noopener noreferrer" className="hover:text-blue-600">
                TELEGRAM
            </a>
          </div>
        </div>
        <div className="w-9/12 h-0.5 bg-green-300 rounded" />
        <div className="flex flex-col items-center justify-between w-9/12 gap-3 lg:flex-row">
          <div className="flex flex-col items-center gap-3 sm:flex-row sm:gap-10">
            <div className="flex items-center gap-2">
              <label className="text-lg tracking-wide">PEPEHERPE</label>
            </div>
            <label className="text-xs">
              &#169; PEPEHERPE {new Date().getFullYear()}
            </label>
          </div>
          <div className="flex gap-5 text-2xl"></div>
        </div>
      </div>
    </footer>
  );
};
