import {
  connectWallet,
  getCurrentWalletConnected,
  pledgeFunds,
  claimTokens,
  getTotalBNBRaised
} from "../Components/utils/walletInteract";
import { useState, useEffect } from "react";
import logo from './logo4.png';

const ethereum = window.ethereum;
if (ethereum) {
  ethereum.on("accountsChanged", function (accounts) {
    console.log(accounts[0]);
  });
}

export const CrowdSale = () => {
  const [price, setCurrentPrice] = useState(0);
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [pledgeAmount, setPledge] = useState("");
  const [totalBNBRaised, setTotalBNBRaised] = useState(0);

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`https://metamask.io/download.html`}
          >
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  useEffect(() => {
    async function magic() {
      const { address, status } = await getCurrentWalletConnected();
      setWallet(address);
      setStatus(status);

      const raisedAmount = await getTotalBNBRaised();
      setTotalBNBRaised(raisedAmount);

      addWalletListener();
    }
    magic();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const submitPledge = async () => {
    await pledgeFunds(pledgeAmount);
    setStatus(status);
  };

  return (
    <body>
      <div className="flex justify-end align-right py-3 px- md:px-10 bg-gray-50">
        <div className="lg:flex gap-5 justify-right">
          <button
            id="walletButton"
            onClick={connectWalletPressed}
            className="bg-green-900 text-white border border-green-900 hover:bg-green-800 px-4 py-2 rounded"
          >
            {walletAddress.length > 0 ? (
              "Connected: " +
              String(walletAddress).substring(0, 6) +
              "..." +
              String(walletAddress).substring(38)
            ) : (
              <span>Connect Wallet</span>
            )}
          </button>
        </div>
      </div>
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
        <div className="bg-green-500 max-w-md w-full space-y-8 rounded-xl p-9 shadow-2xl">
          <div className="text-center mt-4">
            Total BNB Raised: {totalBNBRaised}
          </div>
          <div>
            <img src={logo} alt="Description of Image" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Enter the BNB amount you would like to pledge
            </h2>
          </div>
          <form className="mt-8 space-y-4" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="bnbamount justify-end" className="sr-only">
                  BNB Amount
                </label>
                <input
                  id="bnbamount"
                  name="bnbamount"
                  type="number"
                  step="0.001"
                  min="0.001"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="BNB amount"
                  onChange={(event) => setPledge(event.target.value)}
                />
              </div>
              <div className="flex flex-row py-2">
                <button
                  className="group relative flex justify-center px-1 border border-transparent text-sm font-medium rounded-md text-white bg-green-800 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    setCurrentPrice(1000000000 * pledgeAmount);
                    console.log(price);
                    console.log(pledgeAmount);
                  }}
                >
                  Calculate your $HERPES PER BNB
                </button>
                <h1 className="px-2">You will get : {price}</h1>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-green-800 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  pledgeAmount > 0 ? submitPledge() : console.log("cancel");
                }}
              >
                Submit Pledge
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-green-800 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={async () => {
                  try {
                    await claimTokens();
                    console.log("Claimed tokens successfully");
                  } catch (error) {
                    console.error("Error claiming tokens:", error);
                  }
                }}
              >
                Claim
              </button>
            </div>
          </form>
        </div>
      </div>
    </body>
  );
};
