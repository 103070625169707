import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <header className="w-full bg-green-800 text-white shadow-xl shadow-inner">
      <div className="flex items-center justify-between py-3 px-5 md:px-10">
        <div className="flex items-center gap-2">
          <label className="text-3xl tracking-wide">PEPEHERPE</label>
        </div>

        <div className="hidden lg:flex items-end justify-end">
          <a href="https://www.gettheherpes.com" target="_blank" rel="noopener noreferrer" className="font-bold">HOME</a>
        </div>
      </div>
    </header>
  );
};
